import SelectedDataIcon from "./SelectedDataIcon";
const SearchBar= ({
		title="",
		selectedData=[], 
		onRemove=()=>{}, 
		onChange=()=>{},
		name="", 
		value="",
		placeholder=""
	})=>{
	return(
		<div className="inline-flex rounded-md border border-gray-200 h-12 w-full">
			<div className="font-bold text-sm text-gray-500 bg-gray-100 h-full px-4">{title}</div>
			<div className="px-4 inline-flex">
				{
					selectedData.map((item)=>{
						return (
							<span>
								<SelectedDataIcon
									iconText={item.id}
									onClose={()=>onRemove(item)}
								/>
							</span>
						)
					})
				}
			</div>
			<input 
				name={name} 
				value={value}
				onChange={onChange}	
				className="w-full outline outline-0 active:outline-0 focus:outline-0"
				placeholder={placeholder}
			/>
		</div>
	)
}

export default SearchBar;