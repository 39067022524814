import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDown, PersonFill } from 'react-bootstrap-icons';
import { useContext } from 'react';
import AuthContext from '../../Context/AuthProvider';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ProfileHeader(props) {
  const {logoutUser, user} = useContext(AuthContext);
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="bg-red-700 border border-red-900 text-white inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm">
          <PersonFill className="h-5 w-5"/>
          {user.username}
          <ChevronDown className="-mr-1 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right border border-gray-300 rounded-md bg-white shadow-lg focus:outline-none">
          <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-4 py-2 text-left text-sm'
                    )}
										onClick={()=>{logoutUser()}}
                  >
										Logout
                  </button>
                )}
              </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
