import { X } from "react-bootstrap-icons";
const SelectedDataIcon = ({text="a", iconText="i", onClose=()=>{}})=>{
	return(
		<>
			<div className='relative flex justify-center items-center mt-2 mr-2'>
				{/* inner circle */}
				<div className='bg-secondary rounded-full h-7 w-7 items-baseline overflow-hidden'>
					{/* text inside inner circle */}
					<div className="text-center">
						{iconText}
					</div>
				</div>
				{/* close button */}
				<div onClick={onClose} className="absolute border-2 border-white h-5 w-5 rounded-full bg-red-500 bottom-4 left-4 hover:scale-125 transition duration-150 ease-in-out cursor-pointer">
					<X className="text-white"/>
				</div>
			</div>
		</>
	)
}

export default SelectedDataIcon;