import {useForm} from '../../../../Hooks';
import axios from 'axios';
import { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../../Context/AuthProvider';
import { useData } from '../../../../Hooks';
import { Columns } from 'react-bootstrap-icons';

const rooms_api = process.env.REACT_APP_API_ROOMS;
const buildings_api = process.env.REACT_APP_API_BUILDINGS;
const Create = ({onSuccess = undefined, onError = undefined})=>{

	const {authTokens} = useContext(AuthContext);

	// const [buildings, setBuildings] = useState([]);
	const { data: buildings } = useData(buildings_api, authTokens);

	const { formData, setFormData, handleInputChange } = useForm({
		name:'',
		building: buildings[0]?.id,
	});

	const handleSuccess = (res)=>{
		alert('successfully created!');
		setFormData({
			name: '',
			building: buildings[0]?.id
		});	
		if(onSuccess){onSuccess()}
	}
	const handleError=(err)=>{
		alert('error!');
		if(onError){onError()};
	}

	const handleSubmit = async (e)=>{
		e.preventDefault();
		await axios(
			{
				url: rooms_api + '/',
				data: {
					...formData,
					building: formData.building
				},
				method: 'POST',
				headers: {
					Authorization: `Token ${authTokens}`
				}
			}
		)
			.then(res=> handleSuccess(res))
			.catch(err=> handleError(err));
	}
	return(
		<>
			<form className="w-full" onSubmit={handleSubmit}>
				<div>
					<label class="block text-sm font-medium leading-6 text-gray-900">Building</label>
					<div class="relative mt-2 rounded-md shadow-sm">
						<select onChange={handleInputChange} value={formData.building} name="building" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 cursor-pointer">
							{buildings.map((building)=>{
								return(
									<option value={building.id}>{building.name}</option>
								)
							})}	
						</select>
					</div>
				</div>
				<div>
					<label class="block text-sm font-medium leading-6 text-gray-900">Name</label>
					<div class="relative mt-2 rounded-md shadow-sm">
						<input onChange={handleInputChange} type="text" name="name" value={formData.name} class="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Name"/>
					</div>
				</div>
				<div>
					<div class="relative mt-2 rounded-md shadow-sm">
						<button type="submit" className="bg-amber-200 outline outline-1 outline-amber-600 hover:bg-amber-400 hover:scale-[1.03] gap-2 text-black flex flex-row justify-center items-center shadow-md text-sm font-bold py-2 px-4 rounded-md">
							<Columns/>
							Create Room
						</button>
					</div>
				</div>
			</form>
		</>
	)
}
export default Create;