import { useState, useContext } from "react";
import { useData } from "../../Hooks";
import {SearchBar, PopoverMenu, AddedFilters, Table_, Modal} from '../../Components';
import AuthContext from "../../Context/AuthProvider";
import { useNavigate } from "react-router-dom";
import Create from "./Create";
import { Send } from "react-bootstrap-icons";
import Pagination from "../../Components/Pagination";
import { convertIsoDateToDayAndHour } from "../../Utils/utils";

const alerts_api = process.env.REACT_APP_API_ALERTS;
const alert_alert_recipients = process.env.REACT_APP_API_ALERT_ALERT_RECIPIENTS;
const Alerts = ()=>{
	const [modal, setModal] = useState(false);
	const {authTokens} = useContext(AuthContext);
	const {data, handleQuery, selectedData, query, removeFromQuery, page, setPage, count, totalPages} = useData(alert_alert_recipients, authTokens);
	const navigate = useNavigate();

	return(
		<div className="py-16">
			{/* header */}
			<div className="flex flex-row justify-between">
				<h1 className="font-bold text-lg">Alerts</h1>
			</div>
			<div className="flex flex-row gap-2 justify-between items-center mb-2">

				{/* all the added filters */}
				<AddedFilters filters={query} onRemove={removeFromQuery}/>

				{/* filters menu */}
				<PopoverMenu>
					<PopoverMenu.Button className="h-12 items-center">
						Filters
					</PopoverMenu.Button>
					<PopoverMenu.Items>
						<PopoverMenu.Item>
							<label for="date_created">Alert code / id</label>
							<input 
								onChange={handleQuery}
								className="border border-gray-200 rounded-md px-2"
								type="number" 
								name="id" 
								id="id" 
							/>
						</PopoverMenu.Item>
						<PopoverMenu.Item>
							<label for="date_created">Date alerted</label>
							<input 
								onChange={handleQuery}
								className="border border-gray-200 rounded-md px-2"
								type="date" 
								name="date_created" 
								id="date_created" 
							/>
						</PopoverMenu.Item>
					</PopoverMenu.Items>
				</PopoverMenu>
			</div>
			<div class="flex flex-row justify-start gap-2">
				<SearchBar 
					selectedData={selectedData}
					title="Search"
					name="search"
					value={query.search}
					placeholder="Search by keyword"
					onChange={handleQuery}
				/>
				<div className="inline-flex">
					{/* <button onClick={()=>{handleReports('all')}} className="bg-gradient text-white text-sm font-bold py-2 px-4 rounded-md">Report All</button>					
					<button onClick={()=>{handleReports('selected')}} className="bg-primary text-white text-sm font-bold py-2 px-4 rounded-md">Report Selected</button>					
					<button onClick={()=>{handleReports('all_except_selected')}} className="bg-secondary text-white text-sm font-bold py-2 px-4 rounded-md">Report All Except Selected</button>					 */}
					<span>
					<PopoverMenu>
						<PopoverMenu.Button className="h-12">
							<div className="flex flex-row items-center">
								<span>
									Actions
								</span>
							</div>
						</PopoverMenu.Button>
						<PopoverMenu.Items>
							<PopoverMenu.Item>
								<button onClick={()=>{setModal(true)}} className="bg-amber-200 outline outline-1 outline-amber-600 hover:bg-amber-400 hover:scale-[1.03] gap-2 text-black flex flex-row justify-center items-center shadow-md text-sm font-bold py-2 px-4 rounded-md w-full">
									<span>Make an Alert</span>
									<span><Send/></span>
								</button>					
							</PopoverMenu.Item>
							<PopoverMenu.Item>
								<button onClick={()=>{navigate('/reports')}} className="bg-orange-300 hover:bg-orange-400 outline outline-1 outline-orange-500 hover:scale-[1.03] hover:shadow-lg gap-2 text-black flex flex-row justify-center items-center shadow-md text-sm font-bold py-2 px-4 rounded-md w-full">Make an Alert from Reports</button>					
							</PopoverMenu.Item>
						</PopoverMenu.Items>
					</PopoverMenu>
				</span>
				</div>
			</div>
			{/* modal */}
			<Modal 
				isActive={modal} 
				onClose={()=>{setModal(false)}}
				handleModal={setModal}
				title="Add Alert"
				className={"w-full lg:w-8/12 sm:max-w-none max-w-none"}
			>
				<Create
					onSuccess={()=>{setModal(false)}}
					recipients={selectedData}
				/>
			</Modal>
			{/* table */}
			<div className="mt-3">
				<Table_>
					<Table_.Head>
						{['alert code / id', 'recipient IDs', 'date alerted', 'message'].map((e)=>{
							return(
								<Table_.Th>{e}</Table_.Th>
							)
						})}
					</Table_.Head>
					<Table_.Body>
						{/* iterate users */}
						{data.map((row)=>{
							return(
								<Table_.Tr>
									{['id', 'recipient','date_created', 'message'].map((column)=>{
										if (column === 'date_created'){
											return(
												<Table_.Td>
													{convertIsoDateToDayAndHour(row[column])}
												</Table_.Td>
											)
										}else if(column === 'recipient'){
											return(
												<Table_.Td>
												<div className="max-w-xs inline-flex flex-wrap gap-2">
													{row[column].map((e)=>{
														return(
															<div className="w-12 border border-gray-200 w-min p-1 rounded-md ">{e}</div>
														)
													})}
												</div>
												</Table_.Td>
											)
										}
										else{
											return(
												<Table_.Td>{row[column]}</Table_.Td>
											)
										}
									})}	
								</Table_.Tr>
							)
						})}
					</Table_.Body>
				</Table_>
			</div>
			<div className="mt-3">
				<Pagination
					page={page}
					setPage={setPage}
					count={count}
					totalPages={totalPages}
				/>
			</div>
		</div>
	)
}

export {Alerts}; 