import { Tab } from "@headlessui/react";
import Buildings from "./Tabs/Buildings/Buildings.js";
import Rooms from "./Tabs/Rooms/Rooms.js";
const BuildingsRooms = ()=>{

	return(
		<div className="py-16">
			{/* header */}
			<Tab.Group>
				<Tab.List>
					<div class="flex flex-row justify-start gap-8 items-center mb-8">
						<Tab className={({selected})=>selected ? 'text-slate-700 font-bold  border-b-2 border-slate-900': 'text-md font-regular'}>
							Buildings
						</Tab>
						<Tab className={({selected})=>selected ? 'text-slate-700 font-bold  border-b-2 border-slate-900': 'text-md font-regular'}>
							Rooms
						</Tab>
					</div>
				</Tab.List>
				<Tab.Panels>
					<Tab.Panel>
						<Buildings/>
					</Tab.Panel>
					<Tab.Panel>
						<Rooms/>
					</Tab.Panel>
				</Tab.Panels>
			</Tab.Group>
		</div>
	)
}

export {BuildingsRooms}; 