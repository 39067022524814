// useData hook

import { useState, useEffect } from 'react';
import axios from 'axios';


const useData = (url, token, ordering = null)=>{
	const [query, setQuery] = useState(
		{
		}
	);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [selectedData, setSelectedData] = useState([]);
	const [filters, setFilters] = useState([]);
	const [count, setCount] = useState(0);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	useEffect(() => {
		const fetchData = async ()=>{
      setLoading(true);
      if(error) setError("");
			// get key value pairs from query object
			const keys = Object.keys(query);
			const values = Object.values(query);
			// join keys and values into a query string
			const queryString = keys.map((key, index)=>`${key}=${values[index]}`).join('&');
			const response = await axios(
				{
					url: `${url}/?ordering=${ordering ? ordering : '-date_created'}&page=${page}&${queryString}`,
					method: 'GET',
					headers:{
						Authorization: `Token ${token}`
					}
				});
      setData(response.data.results);
			setTotalPages(response.data.total_pages);
			setCount(response.data.count);
      setLoading(false);
    }
    fetchData().catch(error => {
      setError(error?.message);
      setLoading(false);
    });

		// 
	}, [query, page]);

	useEffect(()=>{
		let newQuery = {...query, page: 1};
		setQuery(query);
	},[query.search]);
	const handleQuery = (e) => {
		if (e.target.name === "search") {
			setPage(1);
		}
		setQuery({ ...query, [e.target.name] : e.target.value })
		console.log(query);
	}
	const addToSelected = (object)=>{
		// check if object is already in selectedData
		// if not, add to selectedData
		// if yes, remove from selectedData
		const index = selectedData.findIndex((item)=>item.id === object.id);
		if(index === -1){
			setSelectedData([...selectedData, object]);
		}
		// else{
		// 	const newSelectedData = [...selectedData];
		// 	newSelectedData.splice(index, 1);
		// 	setSelectedData([...newSelectedData]);
		// }
	}

	const removeFromSelected = (object)=>{
		// check if object is already in selectedData
		// if exits, remove from selectedData
		const index = selectedData.findIndex((item)=>item.id === object.id);
		if(index !== -1){
			const newSelectedData = [...selectedData];
			newSelectedData.splice(index, 1);
			setSelectedData([...newSelectedData]);
		}
	}

	const removeFromQuery = (key)=>{
		const newQuery = {...query};
		delete newQuery[key];
		console.log(newQuery);
		setQuery({...newQuery});	
	}

	const nextPage = ()=>{
		setPage(page+1);
		setQuery({...query, page: page+1});
	}

	const prevPage = ()=>{
		setPage(page-1);
		setQuery({...query, page: page-1});
	}

	return {
		data, setData, 
		selectedData, setSelectedData, addToSelected, removeFromSelected, 
		query, handleQuery, removeFromQuery,
		loading, error,
		nextPage, prevPage,	setPage,
		totalPages,
		page,
		count
	}
}

export default useData;