import { useForm, useData } from '../../Hooks';
import axios from 'axios';
import { Send } from 'react-bootstrap-icons';

const users_api = process.env.REACT_APP_API_USERS;
const groups_api = process.env.REACT_APP_API_GROUPS;

const Create = ({authTokens})=>{
	const {formData, setFormData} = useForm({
		groups: [],
		username: '',
		password: '',
	});
	const {data: groups} = useData(groups_api, authTokens);

	const handleInputChange = (e)=>{
		// if e target name is groups, then we need to check if the value is already in the array
		if(e.target.name === 'groups'){
			if(formData.groups.includes(e.target.value)){
				setFormData({...formData, groups: formData.groups.filter((group)=>group !== e.target.value)});
			}else{
				setFormData({...formData, groups: [...formData.groups, e.target.value]});
			}
		}else{
			setFormData({...formData, [e.target.name]: e.target.value});
		}
		console.log('state:', formData);
	}	

	const handleSubmit = async (e)=>{
		e.preventDefault();
		await axios({
			url: `${users_api}/`,
			method: 'POST',
			data: formData,
			headers: {
				'Authorization': `Token ${authTokens}`
			}
		}).then(()=>alert('success')).catch(err=>alert(JSON.stringify(err)));
	}

	return(
		<>
			<form className="w-full" onSubmit={handleSubmit}>
				<div>
					<label for="username" class="block text-sm font-medium leading-6 text-gray-900">username</label>
					<div class="relative mt-2 rounded-md shadow-sm">
						<input type="text" name="username" onChange={handleInputChange} value={formData.username} id="username" class="block w-full rounded-md border-0 py-1.5 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="username"/>
					</div>
				</div>
				<div>
					<label for="password" class="block text-sm font-medium leading-6 text-gray-900">password</label>
					<div class="relative mt-2 rounded-md shadow-sm">
						<input type="password" name="password" onChange={handleInputChange} value={formData.password} id="password" class="block w-full rounded-md border-0 py-1.5 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="password"/>
					</div>
				</div>
				<div>
					<label for="groups" class="block text-sm font-medium leading-6 text-gray-900">groups (select all that apply)</label>
					<div class="inline-flex flex-wrap gap-2 mt-2 rounded-md shadow-sm">
						{
							groups.map((e)=>(
								<div className="inline-flex gap-2 px-4 py-2 rounded-md border border-gray-200 ">
									<label>{e.name}</label>
									<input type="checkbox" onChange={handleInputChange} name="groups" value={e.id}/>
								</div>
							))
						}
							{/* <div className="inline-flex gap-2 px-4 py-2 rounded-md border border-gray-200 ">
								<label>name</label>
								<input type="radio" name="groups" value="1"/>
							</div> */}
					</div>
				</div>
				<div>
					<div class="relative mt-2 rounded-md shadow-sm">
						<button type="submit" className="bg-amber-200 outline outline-1 outline-amber-600 hover:bg-amber-400 hover:scale-[1.03] gap-2 text-black flex flex-row justify-center items-center shadow-md text-sm font-bold py-2 px-4 rounded-md">
							Submit
							<Send/>
						</button>
					</div>
				</div>
			</form>
		</>
	)
}

export default Create;