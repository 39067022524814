import Layout from "./Layout/Layout";
import { Route, Routes } from "react-router-dom";
import Login from "./Pages/Login/Login";
import './Styles/style.scss'
import { Entries } from "./Pages/Entries";
import { Users} from './Pages/Users';
import { BuildingsRooms } from "./Pages/Buildings/BuildingsRooms";
import Reports from "./Pages/Reports";
import {Alerts} from "./Pages/Alerts/Alerts";
import Diseases from "./Pages/Diseases/";
import { useState, useEffect} from "react";
import {AuthProvider} from "./Context/AuthProvider"; 
function App() {
  return (
    <AuthProvider>
      <Routes>
          <Route element={<Layout/>}>
              <Route path="/users" element={<Users/>}/>
              <Route path="/entries" element={<Entries/>}/>
              <Route path="/diseases" element={<Diseases/>}/>
              <Route path="/buildings-rooms" element={<BuildingsRooms/>}/>
              <Route path="/reports" element={<Reports/>}/>
              <Route path="/alerts" element={<Alerts/>}/>
          </Route>
        <Route>
          <Route path="/" element={<Login/>}/>        
        </Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;
