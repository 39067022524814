import AddedFilterIcon from "./AddedFilterIcon"
const AddedFilters = ({onRemove=()=>{}, filters=[]})=>{
	return(
		<div className="inline-flex gap-2 bg-gray-100 px-4 py-2 rounded-md items-baseline border border-gray-200 w-full h-12">
			<span className="font-bold text-sm text-gray-500">
				Applied Filters: 
			</span>
			<span className="inline-flex">
				{
					// loop query key value pairs
					Object.keys(filters).map((key)=>{
						if(key !== 'search'){
							return(
								<AddedFilterIcon 
									text={`${key} || ${filters[key]}`}
									onClose={()=>{onRemove(key)}}
								/>
							)
						}
					})
				}
			</span>
		</div>
	)
}

export default AddedFilters;