import { RadioGroup } from '@headlessui/react'

const Radio=({onChange=()=>{}, children, value="", defaultValue="days"})=>{

  return (
        <RadioGroup value={value} onChange={onChange}>
					<div className="inline-flex gap-2">
            {children}
					</div>
        </RadioGroup>
  )
}

const Option = ({value="", key="", children})=>{
  return(
    <RadioGroup.Option
      key={key}
      value={value}
      className={({ active, checked }) =>
        `
        ${
          checked ? 'bg-secondary bg-opacity-75 ring-2 ring-yellow-600' : 'border border-gray-200'
        }
          relative flex cursor-pointer rounded-md px-3 py-1 shadow-md focus:outline-none`
      }
    >
      {({ active, checked }) => (
        <>
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center">
              <div className="text-sm">
                <RadioGroup.Label
                  as="p"
                  className={`${checked ? 'text-black': 'text-gray-500'}`}
                >
                  {children}
                </RadioGroup.Label>
              </div>
            </div>
          </div>
        </>
      )}
    </RadioGroup.Option>
  ) 
}

Radio.Option = Option;
export default Radio;