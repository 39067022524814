import { useState, useContext, useEffect } from "react";
import axios from 'axios';
import {Table, Table_, Modal, AddedFilters,	SearchBar, PopoverMenu} from '../Components';
import AuthContext from "../Context/AuthProvider";
import { useData } from "../Hooks";
import { Send } from "react-bootstrap-icons";
import Pagination from "../Components/Pagination";
import { convertIsoDateToDayAndHour } from "../Utils/utils";
import { useForm } from '../Hooks';
const entries_api = process.env.REACT_APP_API_ENTRIES;
const diseases_api = process.env.REACT_APP_API_DISEASES;
const reports_api = process.env.REACT_APP_API_REPORTS;
const rooms_api = process.env.REACT_APP_API_ROOMS;
const buildings_api = process.env.REACT_APP_API_BUILDINGS;

const Entries = ()=>{
	const [modal, setModal] = useState(false);
	const [type, setType] = useState('all');
	const {authTokens} = useContext(AuthContext);
	const {data, query, handleQuery, removeFromQuery, selectedData, addToSelected, removeFromSelected, setPage, page, count, totalPages} = useData(entries_api, authTokens);
	const {data: diseases} = useData(diseases_api, authTokens);
	const {data: rooms} = useData(rooms_api, authTokens);
	const {data: buildings} = useData(buildings_api, authTokens);
	const {formData, setFormData, handleInputChange} = useForm({disease:0});	

	useEffect(()=>{
		setFormData({...formData, disease: diseases[0]?.id})
	},[diseases]);

	const handleReports = (type)=>{
		setModal(true);
		setType(type);
	}

	const handleSubmit = async (e)=>{
		e.preventDefault();	
		let reports;
		switch(type){
			case 'all':
				reports = [...data].map((e)=>{return {
					entry: e.id,
					disease: 1
				}})
				await axios(
					{
						url: reports_api + '/',
						method: 'POST', 
						data: reports,
						headers: {
							Authorization: `Token ${authTokens}`
						}
					}
				).then(res=>'success').catch(err=>alert(JSON.stringify(err)));
				break;
			case 'selected':
				reports = [...selectedData].map((e)=>{
					return {
						entry: e.id, 
						disease: formData.disease
					}
				});
				
				await axios(
					{
						url: reports_api + '/',
						method: 'POST', 
						data: reports,
						headers: {
							Authorization: `Token ${authTokens}`
						}
					}
				).then(res=>alert('success')).catch(err=>alert('an error occured'));
				break;
			case 'all_except_selected':
				reports = [...data].filter((e)=>{return !e?.checked})
														.map((e)=>{return{
															entry: e.id,
															disease: 1
														}});
				await axios(
					{
						url: reports_api + '/',
						method: 'POST', 
						data: reports,
						headers: {
							Authorization: `Token ${authTokens}`
						}
					}
				).then(res=>'success').catch(err=>alert(JSON.stringify(err)));
				break;
		}
	}

	return(
		<div className="py-16">
			{/* header */}
			<div className="flex flex-row justify-between">
				<h1 className="font-bold text-lg">Entries</h1>
			</div>
			{/* filters row */}
			<div class="flex flex-row justify-start gap-2 mb-2">

				<AddedFilters onRemove={removeFromQuery} filters={query}/>			

				{/* filters menu */}
				<PopoverMenu>
					<PopoverMenu.Button className="h-12 items-center">
						Filters
					</PopoverMenu.Button>
					<PopoverMenu.Items>
						<PopoverMenu.Item>
							<label for="date_created">Entry id / code</label>
							<input 
								onChange={handleQuery}
								className="border border-gray-200 rounded-md px-2"
								type="number" 
								name="id" 
								id="id" 
							/>
						</PopoverMenu.Item>
						<PopoverMenu.Item>
							<label for="disease">Building</label>
							<select onChange={handleQuery} name="building" className="border border-gray-200 rounded-md px-2 w-full" placeholder="rooms">
								<option value="">--null--</option>
								{buildings?.map((e)=>{
									return (
										<option value={e.id}>{e.name}</option>
									)
								})}
							</select>
						</PopoverMenu.Item>
						<PopoverMenu.Item>
							<label for="disease">Room</label>
							<select onChange={handleQuery} name="room" className="border border-gray-200 rounded-md px-2 w-full" placeholder="rooms">
								<option value="">--null--</option>
								{rooms?.map((e)=>{
									return (
										<option value={e.id}>{e.name}</option>
									)
								})}
							</select>
						</PopoverMenu.Item>
						<PopoverMenu.Item>
							<label for="date_created">Date Created</label>
							<input 
								onChange={handleQuery}
								className="border border-gray-200 rounded-md px-2"
								type="date" 
								name="date_created" 
								id="date_created" 
							/>
						</PopoverMenu.Item>
						{/* /filters menu */}

						<PopoverMenu.Item>
							<label for="date_exited">Date Exited</label>
							<input 
								onChange={handleQuery}
								className="border border-gray-200 rounded-md px-2"
								type="date" 
								name="date_exited" 
								id="date_exited" 
							/>
						</PopoverMenu.Item>
					</PopoverMenu.Items>
				</PopoverMenu>
			</div>

			{/* searchbar and selected entries row */}
			<div class="flex flex-row justify-start gap-2">
				<SearchBar 
					selectedData={selectedData}
					onRemove={removeFromSelected}
					title="Selected Entries"
					name="search"
					value={query.search}
					placeholder="Search Entries by room, date created, etc."
					onChange={handleQuery}
				/>
				<div className="inline-flex">
					{/* <button onClick={()=>{handleReports('all')}} className="bg-gradient text-white text-sm font-bold py-2 px-4 rounded-md">Report All</button>					
					<button onClick={()=>{handleReports('selected')}} className="bg-primary text-white text-sm font-bold py-2 px-4 rounded-md">Report Selected</button>					
					<button onClick={()=>{handleReports('all_except_selected')}} className="bg-secondary text-white text-sm font-bold py-2 px-4 rounded-md">Report All Except Selected</button>					 */}
					<span>
					<PopoverMenu>
						<PopoverMenu.Button className="h-12">
							<div className="flex flex-row items-center">
								<span>
									Actions
								</span>
							</div>
						</PopoverMenu.Button>
						<PopoverMenu.Items>
							<PopoverMenu.Item>
								<button onClick={()=>{handleReports('selected')}} className="bg-amber-200 outline outline-1 outline-amber-600 hover:bg-amber-400 hover:scale-[1.03] gap-2 text-black flex flex-row justify-center items-center shadow-md text-sm font-bold py-2 px-4 rounded-md w-full">
									<span>Report Selected Entries</span>
									<span><Send/></span>
								</button>					
							</PopoverMenu.Item>
						</PopoverMenu.Items>
					</PopoverMenu>
				</span>
				</div>
			</div>
			{/* table */}
			<div className="mt-3">
				{/* <Table 
					data={data} 
					onRowClick= {addToSelected}
					columns={[
						{ column: 'id', name:'scan id/code'},
						{ column: 'room_name', name:'room'},
						{ column: 'date_created', name:'date entered'},
						{ column: 'date_exited', name:'date exited'},
					]}	
				/> */}
				<Table_>
					<Table_.Head>
						{['entry(scans) code / id ', 'building', 'room', 'date entered', 'date exited', 'user code / id'].map((e)=>{
							return(
								<Table_.Th>{e}</Table_.Th>
							)
						})}
					</Table_.Head>
					<Table_.Body>
						{/* iterate users */}
						{data.map((row)=>{
							return(
								<Table_.Tr onClick={()=>addToSelected(row)}>
									{['id', 'building_name', 'room_name', 'date_created', 'date_exited', 'person', ].map((column)=>{
										if (column === 'date_created' || column === 'date_exited'){
											return(
												<Table_.Td>
													{convertIsoDateToDayAndHour(row[column])}
												</Table_.Td>
											)
										}
										return(
											<Table_.Td>{row[column]}</Table_.Td>
										)
									})}	
								</Table_.Tr>
							)
						})}
					</Table_.Body>
				</Table_>
			</div>
			<div className="mt-3">
				<Pagination
					page={page}
					count={count}
					setPage={setPage}
					totalPages={totalPages}
				/>
			</div>
			<Modal 
				isActive={modal} 
				onClose={()=>setModal(false)}
				handleModal={setModal}
				title="Select Disease to be Reported"
			>
			<form onSubmit={handleSubmit} className="w-full">
				<div>
					<div className="mb-20">
						<label for="price" class="inline-block text-sm font-medium leading-6 text-gray-900 mr-4">Diseases</label>
						<div class="inline-block mt-2 rounded-md shadow-sm">
							<select onChange={handleInputChange} name="disease">
								{diseases.map((e)=>{return(
									<option value={e.id}>{e.name}</option>
								)})}
							</select>
						</div>
					</div>
					<div class="relative mt-2 rounded-md shadow-sm">
						<button className="bg-amber-200 outline outline-1 outline-amber-600 hover:bg-amber-400 hover:scale-[1.03] gap-2 text-black flex flex-row justify-center items-center shadow-md text-sm font-bold py-2 px-4 rounded-md" type="submit">Submit</button>
					</div>
				</div>
			</form>
			</Modal>
		</div>
	)
}

export {Entries}; 