import useForm from "../../Hooks/useForm";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import './Styles/style.scss'
import AuthContext from "../../Context/AuthProvider";
const Login = (props)=>{
	const {formData, handleInputChange} =  useForm(
		{
			username: '',
			password: ''
		});
	const {loginUser, authTokens} = useContext(AuthContext);
	useEffect(() => {
		if(authTokens){
			navigate('/entries');
		}
	}, [authTokens]);
	const handleSubmit = (e)=>{
		e.preventDefault();
		const username = formData.username;
		const password = formData.password;
		username.length > 0 && loginUser(username, password);
	}
	const navigate = useNavigate();
	// useEffect(() => {
	// 	if(props.token !== null) navigate('/entries');
	// }, []);
	return(
		<>
		<div className="bg-prism">
			<div className="container mx-auto h-screen flex flex-col justify-center items-center">
				<div className="h-4/6 lg:w-4/6 grid grid-cols-2">
					<div className="bg-primary flex flex-col gap-12 col-span-1 px-10 py-12">
						<div className="text-secondary font-bold text-3xl">IITrace</div>
						<div className="text-white">
							Empowering MSU-IIT with IITrace: Revolutionizing disease contact-tracing through QR Codes for a safer campus
						</div>
					</div>
					<div className="col-span-1 px-10 py-12 bg-gray-100">
						<form onSubmit={handleSubmit}>
							<div className="flex flex-col justify-center">
								<div>
									Username
									<div>
										<input className="border border-gray-400 w-full h-10" placeholder="Username" value={formData.username} onChange={handleInputChange} type="text" name="username" />
									</div>
								</div>
								<div>
									Password
									<div>
										<input className="border border-gray-400 w-full h-10" placeholder="Password" value={formData.password} onChange={handleInputChange} type="password" name="password"/>
									</div>
								</div>
								<button className="bg-gradient text-white font-bold py-3">Login</button>
							</div>
						</form>	
					</div>
				</div>
			</div>
		</div>
		</>
	)
}
export default Login;