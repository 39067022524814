// make a pagination component

import React from 'react'
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';

const Pagination = ({count, page, setPage, totalPages}) => {
	let pages = [];
	for(let i=1; i<totalPages + 1; i++){
		pages.push(i);
	}
	return (
		<div className="">
			<nav aria-label="Page navigation">
				<p>total results: {count}</p>
				<ul className="pagination flex flex-row gap-1 items-center">
					<li className="page-item">
						<div className="border border-gray-200 p-2 rounded-md" onClick={()=>setPage((page)=>page-1)}><ChevronLeft/></div>
					</li>
					{pages.map((pagenum)=>{
						return (
							<li>
								<div className={`cursor-pointer border py-1 px-2 rounded-md ${pagenum === page ? 'border-gray-300 bg-gray-100 ring-2 ring-gray-200': 'border-gray-200'}`} onClick={()=>setPage(pagenum)}>{pagenum}</div>
							</li>
						)
					})}
					<li className="page-item">
						<div className={`border p-2 rounded-md border-gray-200`} onClick={()=>setPage((page)=>page+1)}><ChevronRight/></div>
					</li>
				</ul>
			</nav>
		</div>
	)
}

export default Pagination
//

