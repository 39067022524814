import AuthContext from "../../../../Context/AuthProvider";
import { useContext, useState } from "react";
import { Building } from "react-bootstrap-icons";
import axios from 'axios';
import Edit from "../../Components/Edit";
import {Table, Modal, SearchBar} from '../../../../Components';
import {useData} from "../../../../Hooks";
import Create from './Create';
import Pagination from "../../../../Components/Pagination";

const buildings_api = process.env.REACT_APP_API_BUILDINGS;

const Buildings = ()=>{

	const [modal, setModal] = useState(
		{
			create_form: false,
			edit_form: false,
			delete_form: false,
		}
	);

	const {authTokens} = useContext(AuthContext);

	const {data, query, handleQuery, page, setPage, count, totalPages} = useData(buildings_api, authTokens);

	const deleteRowClick = async (value)=>{
		await axios(
			{
				url: `${buildings_api}/${value.id}/`,
				method: 'DELETE', 
				headers: {
					Authorization: `Token ${authTokens}`
				}
			}
		)
			.then(res=> alert('successfully deleted'))
			.catch(err=> alert(JSON.stringify(err)));
	}

	return(
		<>
		{/* head  */}
			<div class="flex flex-row justify-between gap-4 items-center">
				<div className="inline-flex gap-4">
					<button onClick={()=>{setModal({...modal, create_form :true})}} className="bg-amber-200 outline outline-1 outline-amber-600 hover:bg-amber-400 hover:scale-[1.03] gap-2 text-black flex flex-row justify-center items-center shadow-md text-sm font-bold py-2 px-4 rounded-md">
						Create Building
					</button>										
				</div>	
				<SearchBar onChange={handleQuery} name="search" title="Search" value={query.search} placeholder="Search building name here"/>
			</div>
			{/* table */}
			<div className="mt-3">
				<Table 
					data={data}
					columns={
						[
							{column: 'name', name: 'building'}
						]
					}
					onEditClick={(value)=>{setModal({...modal, edit_form: {instance: value, status: true}})}}
					onDeleteClick={(value)=>{deleteRowClick(value)}}
				/>
			</div>
			<div className="mt-3">
				<Pagination
					count={count}
					page={page}
					setPage={setPage}
					totalPages={totalPages}
				/>
			</div>
			{/* Modals */}
			<Modal 
				title='Create Building'
				// handleModal={setModal}
				onClose={()=>{setModal({...modal, create_form: false})}}
				isActive={modal.create_form}
			>
				<Create/>
			</Modal>
			<Modal 
				title='Edit Building'
				onClose={()=>{setModal({...modal, edit_form:{instance: null, status:false} })}}
				isActive={modal.edit_form.status}
			>
				<Edit 
					url={buildings_api} 
					authTokens={authTokens} 
					onSuccess={()=>setModal({...modal, edit_form: {instance:null, status:false}})}
					instance={modal.edit_form.instance}
				/>
			</Modal>
		</>		
	);
}
export default Buildings;