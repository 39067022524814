import { useState, useContext } from "react";
import {Table_, Modal, AddedFilters,	SearchBar, PopoverMenu} from '../../Components';
import AuthContext from "../../Context/AuthProvider";
import { useData, useForm } from "../../Hooks";
import { Send, PeopleFill, TrashFill } from "react-bootstrap-icons";
import Alert from "./Alert";
import Delete from "./Delete";
import Create from './Create';
import Pagination from "../../Components/Pagination";

const users_api = process.env.REACT_APP_API_USERS;

const Users = ()=>{
	const [modal, setModal] = useState(false);
	const {authTokens} = useContext(AuthContext);
	const {data, query, handleQuery, removeFromQuery, selectedData, addToSelected, removeFromSelected, page, setPage, count, totalPages} = useData(users_api, authTokens, '-id');
	const headers = ['id', 'username', 'email', 'groups'];	

	const handleActions = (action)=>{
		console.log('action', action);
		switch(action){
			case 'create':
				setModal('create');
				break;
			case 'edit':
				setModal('edit');
				break;
			case 'delete':
				setModal('delete');
				break;
			case 'alert':
				setModal('alert');
				break;
		}	
	}
	console.log(page);
	return(
		<div className="py-16">
			{/* header */}
			<div className="flex flex-row justify-between">
				<h1 className="font-bold text-lg">Users</h1>
			</div>
			{/* filters row */}
			<div class="flex flex-row justify-start gap-2 mb-2">

				<AddedFilters onRemove={removeFromQuery} filters={query}/>			

				{/* filters menu */}
				<PopoverMenu>
					<PopoverMenu.Button className="h-12 items-center">
						Filters
					</PopoverMenu.Button>
					<PopoverMenu.Items>
						<PopoverMenu.Item>
							<label for="date_created">User id / code</label>
							<input 
								onChange={handleQuery}
								className="border border-gray-200 rounded-md px-2"
								type="number" 
								value={query.id}
								name="id" 
								id="id" 
							/>
						</PopoverMenu.Item>
						<PopoverMenu.Item>
							<label for="username">username</label>
							<input 
								onChange={handleQuery}
								className="border border-gray-200 rounded-md px-2"
								type="text" 
								name="username" 
								id="username" 
								value={query.username}
							/>
						</PopoverMenu.Item>
					</PopoverMenu.Items>
				</PopoverMenu>
			</div>

			{/* searchbar and selected entries row */}
			<div class="flex flex-row justify-start gap-2">
				<SearchBar 
					selectedData={selectedData}
					onRemove={removeFromSelected}
					title="Selected Users"
					name="search"
					value={query.search}
					placeholder="Search Users by username and or id"
					onChange={handleQuery}
				/>
				<div className="inline-flex">
					{/* <button onClick={()=>{handleReports('all')}} className="bg-gradient text-white text-sm font-bold py-2 px-4 rounded-md">Report All</button>					
					<button onClick={()=>{handleReports('selected')}} className="bg-primary text-white text-sm font-bold py-2 px-4 rounded-md">Report Selected</button>					
					<button onClick={()=>{handleReports('all_except_selected')}} className="bg-secondary text-white text-sm font-bold py-2 px-4 rounded-md">Report All Except Selected</button>					 */}
					<span>
					<PopoverMenu>
						<PopoverMenu.Button className="h-12">
							<div className="flex flex-row items-center">
								<span>
									Actions
								</span>
							</div>
						</PopoverMenu.Button>
						<PopoverMenu.Items>
							<PopoverMenu.Item>
								<button onClick={()=>{handleActions('alert')}} className="bg-amber-200 outline outline-1 outline-amber-600 hover:bg-amber-400 hover:scale-[1.03] gap-2 text-black flex flex-row justify-center items-center shadow-md text-sm font-bold py-2 px-4 rounded-md w-full">
									<span><Send/></span>
									<span>Send Alert To Selected Users</span>
								</button>					
							</PopoverMenu.Item>
							<PopoverMenu.Item>
								<button onClick={()=>{handleActions('create')}} className="bg-orange-300 hover:bg-orange-400 outline outline-1 outline-orange-500 hover:scale-[1.03] hover:shadow-lg gap-2 text-black flex flex-row justify-center items-center shadow-md text-sm font-bold py-2 px-4 rounded-md w-full">
									<span><PeopleFill/></span>
									<span>Create A New User</span>
								</button>					
							</PopoverMenu.Item>
							<PopoverMenu.Item>
								<button onClick={()=>{handleActions('delete')}} className="bg-red-700 hover:bg-red-600 hover:scale-[1.03] outline outline-1 outline-red-900 hover-shadow-lg gap-2 text-white flex flex-row justify-center items-center shadow-md text-sm font-bold py-2 px-4 rounded-md w-full">
									<span><TrashFill/></span>
									<span>Delete Selected Users</span>
								</button>					
							</PopoverMenu.Item>
						</PopoverMenu.Items>
					</PopoverMenu>
				</span>
				</div>
			</div>
			{/* table */}
			<div className="mt-3">
				{/* <Table 
					data={data} 
					onRowClick= {addToSelected}
					columns={[
						{ column: 'id', name:'user id/code'},
						{ column: 'username', name:'username'},
						{ column: 'groups', name:'roles'},
					]}	
				/> */}
				<Table_>
					<Table_.Head>
						{headers.map((e)=>{
							return(
								<Table_.Th>{e}</Table_.Th>
							)
						})}
					</Table_.Head>
					<Table_.Body>
						{/* iterate users */}
						{data.map((row)=>{
							return(
								<Table_.Tr onClick={()=>addToSelected(row)}>
									{Object.keys(row).map((column)=>{
										if (column === 'groups'){
											return(
												<Table_.Td>
													{row[column].map((e)=>{
														return(
															<span className="bg-gray-100 border border-gray-200 rounded-md px-1 py-0.5 mr-0.5">{e}</span>
														)
													})}
												</Table_.Td>
											)
										}
										return(
											<Table_.Td>{row[column]}</Table_.Td>
										)
									})}	
								</Table_.Tr>
							)
						})}
					</Table_.Body>
				</Table_>
			</div>
			<div className="mt-3">
				{/* pagination */}
				
				<Pagination
					page={page}
					setPage={setPage}
					totalPages={totalPages}
					count={count}
				/>
			</div>
			{/* modal */}
			<Modal isActive={modal !== false} onClose={()=>{setModal(false)}}>
				{
					modal==='alert' &&
					<Alert recipients={selectedData} authTokens={authTokens}/>
				}
				{
					modal === 'create' &&
					<Create authTokens={authTokens}/>
				}
				{
					modal === 'delete' &&
					<Delete selectedData={selectedData} authTokens={authTokens}/>
				}
			</Modal>
		</div>
	)
}

export {Users}; 