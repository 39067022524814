import {useForm} from '../../Hooks';
import { Send } from 'react-bootstrap-icons';
import axios from 'axios';

const alert_recipients_url = process.env.REACT_APP_API_ALERT_RECIPIENTS;
const alerts_url = process.env.REACT_APP_API_ALERTS;

const Alert = ({recipients, authTokens})=>{
	const {formData, handleInputChange} = useForm({
		message: '',
	});
	const handleSubmit = async (e)=>{
		e.preventDefault();
		var alert_id = undefined;
		await axios({
			method: 'post',
			url: alerts_url + '/',
			headers: {
				'Authorization': `Token ${authTokens}`,
			},
			data: formData
		}).then((res)=> alert_id = res.data.id).catch(err=>alert(JSON.stringify(err)));

		await Promise.all(recipients.map((e)=> 
			axios({
				method: 'post',
				url: alert_recipients_url + '/',
				headers: {
					'Authorization': `Token ${authTokens}`,
				},
				data: {
					alert: alert_id,
					recipient: e.id
				}
			})
		)).then((res)=>alert('request successful!'))
		.catch(err=>alert(JSON.stringify(err)));
	}

	return(
		<>
			<form className="w-full" onSubmit={handleSubmit}>
				<div>
					<label for="price" class="block text-sm font-medium leading-6 text-gray-900">Message</label>
					<div class="relative mt-2 rounded-md shadow-sm">
						<textarea type="text" name="message" onChange={handleInputChange} value={formData.message} id="message" class="block w-full rounded-md border-0 py-1.5 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Name"></textarea>
					</div>
				</div>
				<div>
					<div class="relative mt-2 rounded-md shadow-sm">
						<button type="submit" className="bg-amber-200 outline outline-1 outline-amber-600 hover:bg-amber-400 hover:scale-[1.03] gap-2 text-black flex flex-row justify-center items-center shadow-md text-sm font-bold py-2 px-4 rounded-md">
							Submit
							<Send/>
						</button>
					</div>
				</div>
			</form>
		</>
	)
}

export default Alert;