import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const login_api = process.env.REACT_APP_API_LOGIN;
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser ] = useState(()=>
    localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null
  );
  
  const [expiry, setExpiry] = useState(()=>
    localStorage.getItem("expiry")
      ? JSON.parse(localStorage.getItem("expiry"))
      : null
  );

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const loginUser = async (username, password) => {
		const response = await axios.post(`${login_api}/`, {
			username: username,
			password: password				
		})
		.then(res=>res)
		.catch(err=>alert(err));
    const data = await response.data;
    if (response.status === 200) {
      setAuthTokens(data.token);
      localStorage.setItem("authTokens", JSON.stringify(data.token));

      setUser(data.user_data);
      localStorage.setItem("user", JSON.stringify(data.user_data));

      setExpiry(data.expiry);
      localStorage.setItem("expiry", JSON.stringify(data.expiry));
			// alert('auth tokens is set', data.token);
      navigate("/entries");
    } else {
      alert("Something went wrong!");
    }
  };

  const logoutUser = () => {
    setAuthTokens(null);
    localStorage.removeItem("authTokens");
    localStorage.removeItem("user");
    localStorage.removeItem("expiry");
    navigate("/");
  };

  const contextData = {
    authTokens,
    setAuthTokens,
    loginUser,
    logoutUser,
    user,
    expiry
  };

  useEffect(() => {
    setLoading(false);
  }, [authTokens, loading]);
 
  useEffect(() => {
    if (expiry) {
      const now = new Date();
      const expiryDate = new Date(expiry);
      if (now.getTime() >= expiryDate.getTime()) {
        logoutUser();
      }
    }
  }, []);
  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};

export {AuthProvider};
export default AuthContext;