import { X } from "react-bootstrap-icons";
const AddedFilterIcon = ({text="", onClose=()=>{}})=>{
	return(
		<>
			<div className='relative flex justify-center items-center mt-2 mr-2'>
				{/* inner circle */}
				<div className='inline-flex bg-secondary rounded-full h-5 items-center pl-2'>
					{/* text inside inner circle */}
					<span className="text-center text-sm">
						{text}
					</span>
					<span className="ml-2 text-center rounded-full bg-red-500 border-2 border-white">
						<X onClick={onClose} className="cursor-pointer text-white"/>
					</span>

				</div>
			</div>
		</>
	)
}

export default AddedFilterIcon;