import { useState, useContext } from "react"
import AuthContext, { AuthProvider } from "../../Context/AuthProvider";
import axios from "axios"
import {Modal, Table, Table_, SearchBar} from '../../Components';
import {useForm, useData} from '../../Hooks';
import { convertIsoDateToDayAndHour,  convertDaysToDDHHMMSS, convertHoursToDDHHMMSS, convertMinutesToDDHHMMSS ,convertDDHHMMSSToReadable} from "../../Utils/utils";
import Radio from "./Components/Radio";
import InfoPopover from "./Components/InfoPopover";
import Pagination from "../../Components/Pagination";
import { Virus2 } from "react-bootstrap-icons";
const diseases_api = process.env.REACT_APP_API_DISEASES
const Diseases = ()=>{
	const {authTokens} = useContext(AuthContext);
	const { data, query, handleQuery, page, setPage, count, totalPages} = useData(diseases_api, authTokens, '-id');
	const [timeFormat, setTimeFormat] = useState(
		{
			incubation_duration: 'days', 
			infection_duration: 'days'
		}
	);
	const [modal, setModal] = useState(false);
	const [editModal, setEditModal] = useState(
		{
			status: false, 
			instance: ''
		}
	);
	const { formData, setFormData, handleInputChange } = useForm({
		'name': '', 
		'infection_duration': '',
		'incubation_duration': ''		
	});

	const handleSubmit = async (e)=>{
		e.preventDefault();
		const convertFormData = ()=>{
			var incubation_duration;
			var infection_duration;
			switch(timeFormat.incubation_duration){
				case('hours'):
					incubation_duration = convertHoursToDDHHMMSS(formData.incubation_duration);
					break;
				case('days'):
					incubation_duration = convertDaysToDDHHMMSS(formData.incubation_duration);
					break;
				default:
					incubation_duration = convertMinutesToDDHHMMSS(formData.incubation_duration); 
					break;
			}	
			switch(timeFormat.infection_duration){
				case('hours'):
					infection_duration = convertHoursToDDHHMMSS(formData.infection_duration);
					break;
				case('days'):
					infection_duration = convertDaysToDDHHMMSS(formData.infection_duration);
					break;
				default:
					infection_duration = convertMinutesToDDHHMMSS(formData.infection_duration);
					break;
			}	
			return {...formData, incubation_duration: incubation_duration, infection_duration: infection_duration}
		}
		await axios(
			{
				url: diseases_api + '/', 
				data: convertFormData(),
				method: 'POST',
				headers: {
					Authorization: `Token ${authTokens}`
				}	
			}	
		)
			.then(res=>alert('success'))
			.catch(err=>alert(JSON.stringify(err)));
	}
	
	const deleteRow = async (row)=>{
		// delete row via axios disease api
		console.log(row)
		await axios(
			{
				url: diseases_api + '/' + row.id + '/',
				method: 'DELETE',
				headers: {
					Authorization: `Token ${authTokens}`
				}
			}
		).then(res=>alert('success')).catch(err=>alert(JSON.stringify(err)));
	}

	const editRow = (row)=>{
		setEditModal({status: true, instance: row});
		setFormData({...row});
	}

	const updateRow  = async (e)=>{
		// update row via axios disease api
		e.preventDefault();	
		const convertFormData = ()=>{
			var incubation_duration;
			var infection_duration;
			switch(timeFormat.incubation_duration){
				case('hours'):
					incubation_duration = convertHoursToDDHHMMSS(formData.incubation_duration);
					break;
				case('days'):
					incubation_duration = convertDaysToDDHHMMSS(formData.incubation_duration);
					break;
				default:
					incubation_duration = convertMinutesToDDHHMMSS(formData.incubation_duration); 
					break;
			}	
			switch(timeFormat.infection_duration){
				case('hours'):
					infection_duration = convertHoursToDDHHMMSS(formData.infection_duration);
					break;
				case('days'):
					infection_duration = convertDaysToDDHHMMSS(formData.infection_duration);
					break;
				default:
					infection_duration = convertMinutesToDDHHMMSS(formData.infection_duration);
					break;
			}	
			return {...formData, incubation_duration: incubation_duration, infection_duration: infection_duration}
		}
		await axios(
			{
				url: diseases_api + '/' + editModal.instance.id + '/',
				data: convertFormData(formData),
				method: 'PUT',
				headers: {
					Authorization: `Token ${authTokens}`
				}
			}
		).then(res=>alert('success')).catch(err=>alert(JSON.stringify(err)));
		setFormData({...formData, name: '', incubation_duration: '', infection_duration: ''});
	}


	return(
		<div className="py-16">
			{/* header */}
			<Modal 
				isActive={modal} 
				handleModal={setModal}
				onClose={()=>{setModal(false)}}
				title="Add Disease"
			>
			<form className="w-full" onSubmit={handleSubmit}>
				<div>
					<label for="price" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
					<div class="relative mt-2 rounded-md shadow-sm">
						<input onChange={handleInputChange} type="text" name="name" class="block w-full rounded-md border-0 py-1.5 px-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
							placeholder="Name"
						/>
					</div>
				</div>
				<div>
					<label for="price" class="inline-flex gap-2 text-sm font-medium leading-6 text-gray-900">
						<span>Incubation Period (in {timeFormat.incubation_duration})</span>
						<InfoPopover>
							<InfoPopover.Button></InfoPopover.Button>
							<InfoPopover.Items>
								The incubation period refers to the time between when a person is exposed to an infectious agent (such as a virus or bacteria) and when they start showing symptoms of the associated disease. 
							</InfoPopover.Items>
						</InfoPopover>
					</label>
					<div class="relative flex flex-row justify-between items-center gap-2 mt-2">
						<input onChange={handleInputChange} type="number" name="incubation_duration" class="w-full block rounded-md border-0 py-1.5 px-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
							placeholder="Incubation Period"
						/>
						<Radio value={timeFormat.incubation_duration} onChange={(value)=>setTimeFormat({...timeFormat, incubation_duration: value})}>
							<Radio.Option value="days">days</Radio.Option>
							<Radio.Option value="hours">hours</Radio.Option>
							<Radio.Option value="minutes">minutes</Radio.Option>
						</Radio>
					</div>
				</div>

				<div>
					<label for="price" class="inline-flex gap-2 text-sm font-medium leading-6 text-gray-900">
						<span>Infection Duration (in {timeFormat.infection_duration})</span>
						<InfoPopover>
							<InfoPopover.Button></InfoPopover.Button>
							<InfoPopover.Items>
							duration of illness or disease, refers to the length of time a person remains symptomatic after the onset of disease
							</InfoPopover.Items>
						</InfoPopover>
					</label>
					<div class="relative flex flex-row justify-between items-center gap-2 mt-2 rounded-md">
						<input onChange={handleInputChange} type="number" name="infection_duration" class="block w-full rounded-md border-0 py-1.5 px-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
							placeholder="Infection Duration"
						/>
						<Radio value={timeFormat.infection_duration} onChange={(value)=>setTimeFormat({...timeFormat, infection_duration: value})}>
							<Radio.Option value="days">days</Radio.Option>
							<Radio.Option value="hours">hours</Radio.Option>
							<Radio.Option value="minutes">minutes</Radio.Option>
						</Radio>
					</div>
				</div>
				<div>
					<div class="relative mt-2 rounded-md shadow-sm">
						<button className="bg-amber-200 outline outline-1 outline-amber-600 hover:bg-amber-400 hover:scale-[1.03] gap-2 text-black flex flex-row justify-center items-center shadow-md text-sm font-bold py-2 px-4 rounded-md" type="submit">
							<Virus2/>
							Create Disease
						</button>
					</div>
				</div>
			</form>
			</Modal>
			<Modal isActive={editModal.status} onClose={()=>{setEditModal({...editModal, instance: '', status: false})}}>
			<form className="w-full" onSubmit={updateRow}>
				<div>
					<label for="price" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
					<div class="relative mt-2 rounded-md shadow-sm">
						<input onChange={handleInputChange} type="text" name="name" class="block w-full rounded-md border-0 py-1.5 px-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
							value={formData.name}
							placeholder="New Disease Name"
						/>
					</div>
				</div>
				<div>
					<label for="price" class="inline-flex gap-2 text-sm font-medium leading-6 text-gray-900">
						<span>New Incubation Period (in {timeFormat.incubation_duration})</span>
						<InfoPopover>
							<InfoPopover.Button></InfoPopover.Button>
							<InfoPopover.Items>
								The incubation period refers to the time between when a person is exposed to an infectious agent (such as a virus or bacteria) and when they start showing symptoms of the associated disease. 
							</InfoPopover.Items>
						</InfoPopover>
					</label>
					<div class="relative flex flex-row justify-between items-center gap-2 mt-2">
						<input onChange={handleInputChange} type="number" name="incubation_duration" class="w-full block rounded-md border-0 py-1.5 px-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
							value={formData.incubation_duration}
							placeholder="Incubation Period"
						/>
						<Radio value={timeFormat.incubation_duration} onChange={(value)=>setTimeFormat({...timeFormat, incubation_duration: value})}>
							<Radio.Option value="days">days</Radio.Option>
							<Radio.Option value="hours">hours</Radio.Option>
							<Radio.Option value="minutes">minutes</Radio.Option>
						</Radio>
					</div>
				</div>

				<div>
					<label for="price" class="inline-flex gap-2 text-sm font-medium leading-6 text-gray-900">
						<span>New Infection Duration (in {timeFormat.infection_duration})</span>
						<InfoPopover>
							<InfoPopover.Button></InfoPopover.Button>
							<InfoPopover.Items>
							duration of illness or disease, refers to the length of time a person remains symptomatic after the onset of disease
							</InfoPopover.Items>
						</InfoPopover>
					</label>
					<div class="relative flex flex-row justify-between items-center gap-2 mt-2 rounded-md">
						<input onChange={handleInputChange} type="number" name="infection_duration" class="block w-full rounded-md border-0 py-1.5 px-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
							value={formData.infection_duration}
							placeholder="Infection Duration"
						/>
						<Radio value={timeFormat.infection_duration} onChange={(value)=>setTimeFormat({...timeFormat, infection_duration: value})}>
							<Radio.Option value="days">days</Radio.Option>
							<Radio.Option value="hours">hours</Radio.Option>
							<Radio.Option value="minutes">minutes</Radio.Option>
						</Radio>
					</div>
				</div>
				<div>
					<div class="relative mt-2 rounded-md shadow-sm">
						<button className="bg-amber-200 outline outline-1 outline-amber-600 hover:bg-amber-400 hover:scale-[1.03] gap-2 text-black flex flex-row justify-center items-center shadow-md text-sm font-bold py-2 px-4 rounded-md" type="submit">Submit</button>
					</div>
				</div>
			</form>
			</Modal>

			{/* search and actions bar */}
			<div class="flex flex-row justify-between gap-4 items-center">
				<div className="inline-flex gap-4">
					<button onClick={()=>{setModal(true)}} className="bg-amber-200 outline outline-1 outline-amber-600 hover:bg-amber-400 hover:scale-[1.03] gap-2 text-black flex flex-row justify-center items-center shadow-md text-sm font-bold py-2 px-4 rounded-md">
						Create New Disease
					</button>										
				</div>	
				<SearchBar
					title="Search"
					value={query.search}
					name="search"
					placeholder="Search by keywords"	
					onChange={handleQuery}
				/>
			</div>
			{/* table */}
			<div class="mt-3">
				<Table_>
					<Table_.Head>
						{['id', 'name', 'incubation_duration', 'infection_duration', 'action'].map((e)=>{
							return(
								<Table_.Th>{e}</Table_.Th>
							)
						})}
					</Table_.Head>
					<Table_.Body>
						{/* iterate users */}
						{data.map((row)=>{
							return(
								<Table_.Tr>
									{['id', 'name', 'incubation_duration', 'infection_duration'].map((column)=>{
										if (column === 'date_created'){
											return(
												<Table_.Td>
													{convertIsoDateToDayAndHour(row[column])}
												</Table_.Td>
											)
										}else if(column === 'incubation_duration' || column === 'infection_duration'){
											return(
												<Table_.Td>
													{convertDDHHMMSSToReadable(row[column])}
												</Table_.Td>
											)				
										}else{
											return(
												<Table_.Td>{row[column]}</Table_.Td>
											)
										}
									})}	
									<Table_.Td>
										<button onClick={()=>editRow(row)} className="bg-orange-300 hover:bg-orange-400 outline outline-1 outline-orange-500 hover:scale-[1.03] hover:shadow-lg text-black items-center shadow-md text-sm font-bold py-1 px-2 rounded-md mr-2">
											Edit
										</button>
										<button onClick={()=>deleteRow(row)} className="bg-red-700 hover:bg-red-600 hover:scale-[1.03] outline outline-1 outline-red-900 hover-shadow-lg text-white items-center shadow-md text-sm font-bold py-1 px-2 rounded-md">
											Delete
										</button>
									</Table_.Td>
								</Table_.Tr>
							)
						})}
					</Table_.Body>
				</Table_>
			</div>
			<div className="mt-3">
				<Pagination
					count={count}
					page={page}
					setPage={setPage}
					totalPages={totalPages}
				/>
			</div>
		</div>
	)
}


export default Diseases;