import {useForm} from "../../../Hooks";
import axios from "axios";

const Edit = ({instance, url = "", authTokens = "", onSuccess =undefined, onError=undefined})=>{
	const { formData, setFormData, handleInputChange  } = useForm({
		...instance
	});


	const handleSuccess = (res)=>{
		setFormData({});
		alert('successfully edited!');
		if(onSuccess){onSuccess()};
	}

	const handleError = (err)=>{
		alert('error!');	
		if(onError){onError()};			
	}

	const handleSubmit = async (e)=>{
		e.preventDefault();	
		const URL = url;
		await axios(
			{
				url: `${URL}/${instance.id}/`,
				method: 'PUT', 
				data: formData,
				headers: {
					Authorization: `Token ${authTokens}`
				}
			}
		)
		.then(res=>handleSuccess(res))
		.catch(err=>handleError(err));
	}
	return(
		<>
		<form onSubmit={handleSubmit}>
			<div className="inline-flex gap-4 items-center">
				<label className="text-slate-800 text-md" htmlFor="name">New Name</label>
				<input className="ring ring-slate-500 rounded-sm px-4 text-slate-700 focus:ring-4 focus:ring-cyan-500 "onChange={handleInputChange} type="text" id="name" name="name" value={formData.name} placeholder="new name"/>
				<button type="submit" className="text-white bg-yellow-400 submit px-4 py-1 rounded-md">submit</button>
			</div>
		</form>
		</>
	)	
}

export default Edit;