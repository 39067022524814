import { List as Hamburger } from "react-bootstrap-icons";
import { Bell } from "react-bootstrap-icons";
import ProfileHeader from "./ProfileHeader";
import { useContext } from "react";
const NavBar = (props)=>{
	
	return(
		<div className="text-white bg-primary flex flex-row justify-between items-center px-6 h-12 fixed z-50 top-0 left-0 right-0">
			<div className="flex flex-row items-center gap-5">
				<div onClick={props.onClick}>
					<Hamburger/>
				</div>
				<div className="text-secondary text-xl font-bold">IITrace</div>
			</div>
			<div className="flex flex-row items-center gap-2">
				<div className="">
					<Bell/>
				</div>
				<div className="flex flex-col">
					<ProfileHeader/>
				</div>
			</div>
		</div>
	)
}

export default NavBar;