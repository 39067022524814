import { 
  convertDaysToMinutes, convertHoursToMinutes, convertTimeToMinutes, 
  convertDaysToDDHHMMSS, convertHoursToDDHHMMSS, convertMinutesToDDHHMMSS,
  convertDDHHMMSSToReadable
} from "./Dates";
import toBase64 from "./base64";
const base64ToImage = (string)=>{
	const prefix = 'data:image/png;base64,'
	const imgSrc = prefix + string;
	return imgSrc
}

const convertIsoDateToDdMmmYyyy= (isoDate)=> {
  const date = new Date(isoDate);
  const year = date.getFullYear();
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate().toString().padStart(2, '0');
  return `${day} ${month} ${year}`;
}

function convertIsoDateToDayAndHour(isoDateString) {
  const date = new Date(isoDateString);
  const year = date.getFullYear();
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${day} ${month} ${year} ${hours}:${minutes}`;
}







export {
	base64ToImage,
	convertIsoDateToDdMmmYyyy,
	convertIsoDateToDayAndHour,	
  convertDaysToMinutes, 
  convertHoursToMinutes,
  convertTimeToMinutes,
  convertDaysToDDHHMMSS, convertHoursToDDHHMMSS, convertMinutesToDDHHMMSS,convertDDHHMMSSToReadable,
  toBase64
}