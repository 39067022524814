import { Popover as Popover_, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { ChevronDown } from 'react-bootstrap-icons';

const PopoverMenu = (props) => {
  return (
    <div className={"w-max text-right" + props.className}>
      <Popover_ as="div" className="relative inline-block text-left">
				{props.children}
			</Popover_>
    </div>
  )
}

const Button = (props)=>{
	return(
		<div>
			<Popover_.Button className={`inline-flex w-full justify-center rounded-md bg-gray-100 border border-gray-200 px-4 py-2 text-sm font-bold text-gray-500 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 ${props.className}`}>
				{props.children}
				<ChevronDown
					className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
					aria-hidden="true"
				/>
			</Popover_.Button>
		</div>
	)	
}

const Item = (props)=>{
	return(
				<div
					className={`'bg-violet-500 group flex w-full items-center rounded-md px-2 py-2 text-sm`}
				>
					{props.children}
				</div>
	)
}

const Items = (props)=>{
	return(
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover_.Panel className="absolute z-40 right-0 mt-2 w-max origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg border border-gray-200">
            <div className="px-1 py-1 ">
							{props.children}
						</div>
					</Popover_.Panel>
				</Transition>
	)
}

PopoverMenu.Button = Button;
PopoverMenu.Items = Items;
PopoverMenu.Item = Item;

export default PopoverMenu;