// convert hh:mm:ss string to minutes
const convertTimeToMinutes = (timeString) => {
	const timeArray = timeString.split(':');
	const hours = parseInt(timeArray[0]);
	const minutes = parseInt(timeArray[1]);
	const seconds = parseInt(timeArray[2]);
	const minutesFromHours = hours * 60;
	const totalMinutes = minutesFromHours + minutes + (seconds / 60);
	return totalMinutes;
}


// convert days to minutes
const convertDaysToMinutes = (days) => {
	return days * 24 * 60;
}

// convert hours to minutes
const convertHoursToMinutes = (hours) => {
	return hours * 60;
}

// convert days to DD HH:MM:SS.uuuuuu
const convertDaysToDDHHMMSS = (days) => {
	const hours = days * 24;
	const remainingHours = Math.floor(hours % 24);
	const minutes = Math.floor((hours - Math.floor(hours)) * 60);
	const seconds = Math.floor((((hours - Math.floor(hours)) * 60) - Math.floor((hours - Math.floor(hours)) * 60)) * 60);
	const microseconds = Math.floor((((((hours - Math.floor(hours)) * 60) - Math.floor((hours - Math.floor(hours)) * 60)) * 60) - Math.floor((((hours - Math.floor(hours)) * 60) - Math.floor((hours - Math.floor(hours)) * 60)) * 60)) * 1000000);
	return `${days} ${remainingHours}:${minutes}:${seconds}.${microseconds}`;
}

// convert hours to DD HH:MM:SS.uuuuuu
const convertHoursToDDHHMMSS = (hours) => {
	const days = Math.floor(hours / 24);
	const remainingHours = Math.floor(hours % 24);
	const minutes = Math.floor((hours - Math.floor(hours)) * 60);
	const seconds = Math.floor((((hours - Math.floor(hours)) * 60) - Math.floor((hours - Math.floor(hours)) * 60)) * 60);
	const microseconds = Math.floor((((((hours - Math.floor(hours)) * 60) - Math.floor((hours - Math.floor(hours)) * 60)) * 60) - Math.floor((((hours - Math.floor(hours)) * 60) - Math.floor((hours - Math.floor(hours)) * 60)) * 60)) * 1000000);
	return `${days} ${remainingHours}:${minutes}:${seconds}.${microseconds}`;
}

// convert minutes to DD HH:MM:SS.uuuuuu
const convertMinutesToDDHHMMSS = (minutes) => {
	const hours = minutes / 60;
	const days = Math.floor(hours / 24);
	const remainingHours = Math.floor(hours % 24);
	const minutes2 = Math.floor((hours - Math.floor(hours)) * 60);
	const seconds = Math.floor((((hours - Math.floor(hours)) * 60) - Math.floor((hours - Math.floor(hours)) * 60)) * 60);
	const microseconds = Math.floor((((((hours - Math.floor(hours)) * 60) - Math.floor((hours - Math.floor(hours)) * 60)) * 60) - Math.floor((((hours - Math.floor(hours)) * 60) - Math.floor((hours - Math.floor(hours)) * 60)) * 60)) * 1000000);
	return `${days} ${remainingHours}:${minutes2}:${seconds}.${microseconds}`;
}


// convert DD HH:MM:SS.uuuuuu to readable
const convertDDHHMMSSToReadable = (ddhhmmss) => {
	let ddhhmmssArray = ddhhmmss.split(' ');
	try{
		const days = parseInt(ddhhmmssArray[0]);
		const hours = parseInt(ddhhmmssArray[1].split(':')[0]);
		const minutes = parseInt(ddhhmmssArray[1].split(':')[1]);
		const days_string = days > 0 ? `${days} day(s)` : '';
		const hours_string = hours > 0 ? `${hours} hour(s)` : '';
		const minutes_string = minutes > 0 ? `${minutes} minute(s)` : '';
		const time_string = [days_string, hours_string, minutes_string].filter((item) => item !== '').join(', ');
		return time_string;
	}catch{
		ddhhmmssArray = ddhhmmss.split(':');	
		const hours = parseInt(ddhhmmssArray[0]);
		const minutes = parseInt(ddhhmmssArray[1]);
		const hours_string = hours > 0 ? `${hours} hour(s)` : '';
		const minutes_string = minutes > 0 ? `${minutes} minute(s)` : '';
		const time_string = [hours_string, minutes_string].filter((item) => item !== '').join(', ');
		return time_string;
	}
}
// 
// 
//

//
export {
	convertTimeToMinutes,
	convertDaysToMinutes,
	convertHoursToMinutes,
	convertDaysToDDHHMMSS,
	convertHoursToDDHHMMSS,
	convertMinutesToDDHHMMSS,
	convertDDHHMMSSToReadable
}
