import NavBar from "./Components/NavBar";
import SideBar from "./Components/SideBar";
import { Outlet, useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import AuthContext from "../Context/AuthProvider";
const Layout = (props)=>{
	const [isActive, setIsActive] =useState(true);
	const { authTokens } = useContext(AuthContext);
	const navigate = useNavigate();
	const toggleActive = ()=>{
		isActive === true ? setIsActive(false): setIsActive(true);
	}
	useEffect(() => {
		if(authTokens == null){		
			navigate('/');		
		}
	}, []);	
	return(
		<>
		<NavBar onClick={()=>{toggleActive()}}/>
		<SideBar isActive={isActive} onBackgroundClick={()=>toggleActive()}/>
		{/* todo: fix spaghetti coding */}
		<div className={`container max-w-6xl px-4 pt-12 `}>
			<div className={`duration-300 ease-in-out ${isActive ? 'ml-52': 'ml-0'}`}>
				<Outlet/>
			</div>
		</div>
		</>
	);
}
export default Layout;