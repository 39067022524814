import axios from 'axios';
const users_api = process.env.REACT_APP_API_USERS;
const Delete = ({selectedData, authTokens})=>{
	const handleSubmit = async (e)=>{
		e.preventDefault();
		Promise.all(selectedData.map((i)=> 
			axios(
				{
					method: 'delete',
					url: `${users_api}/${i.id}`,
					headers: {
						'Authorization': `Token ${authTokens}`
					}
				}
			)))
		.then(
			(responses)=>responses.every((res)=>res.status===204)?alert('successfully deleted'):alert('something went wrong')
		)
		.catch(
			(err)=>alert(JSON.stringify(err))
		);
	}
	return(
		<form className="w-full" onSubmit={handleSubmit}>
			<div>
				<label for="price" class="block text-sm font-medium leading-6 text-gray-900">Are you sure you want to delete these users?</label>
			</div>
			<div>
				<div class="relative mt-2 rounded-md shadow-sm">
					<button type="submit" className="bg-amber-200 outline outline-1 outline-amber-600 hover:bg-amber-400 hover:scale-[1.03] gap-2 text-black flex flex-row justify-center items-center shadow-md text-sm font-bold py-2 px-4 rounded-md">
						Yes, I am sure
					</button>
				</div>
			</div>
		</form>
	)
}

export default Delete;