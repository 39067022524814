import { Person, People } from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";
import {
	Gear,
	DoorClosed,
	Lungs,
	Buildings,
	Flag,
	ExclamationSquare
} from "react-bootstrap-icons";

const SideBar = (props)=>{
	let classname=''
	props.isActive ? classname = 'px-2' : classname='-translate-x-[120%]';
	const linkClass = 'w-full py-2 hover:bg-gray-200 hover:ring-1 ring-slate-300 duration-100 cursor-pointer flex flex-row items-center px-6 gap-3';
	const activeLinkClass = 'w-full py-2 bg-amber-200 hover:bg-amber-300 border-2 border-transparent ring-1 ring-amber-500 ring-offset-1 duration-100 cursor-pointer font-bold flex flex-row items-center px-6 gap-3';
	return(
		<>
			<div onClick={props.onBackgroundClick} className={`${props.isActive ? 'fixed': 'hidden'} top-0 bottom-0 w-full h-full  bg-slate-900 z-40 opacity-40`}></div>
			{/* sidebar */}
			<div className={`fixed top-12 left-0 z-50 ${classname} shadow-xl transition duration-300 ease-in-out flex flex-col justify-start items-start w-full sm:w-64 md:w-52 border-r border-gray-200 gap-2 py-2 h-full bg-white`}>	
				<NavLink to="/users"  className={({isActive})=>{return isActive ? activeLinkClass : linkClass}} exact>
					<span><People/></span>
					<span>Users</span>
				</NavLink>
				<NavLink to="/entries"  className={({isActive})=>{return isActive ? activeLinkClass : linkClass}} exact>
					<span><DoorClosed/></span>
					<span>Entries</span>
				</NavLink>
				<NavLink to="/reports"  className={({isActive})=>{return isActive ? activeLinkClass : linkClass}} exact>
					<span><Flag/></span>
					<span>Reports</span>
				</NavLink>
				<NavLink to="/alerts"  className={({isActive})=>{return isActive ? activeLinkClass : linkClass}} exact>
					<span><ExclamationSquare/></span>
					<span>Alerts</span>
				</NavLink>
				<NavLink to="/buildings-rooms"  className={({isActive})=>{return isActive ? activeLinkClass : linkClass}} exact>
					<span><Buildings/></span>
					<span>Buildings and Rooms</span>
				</NavLink>
				<NavLink to="/diseases" className={({isActive})=>{return isActive ? activeLinkClass : linkClass}} exact>
					<span><Lungs/></span>
					<span>Diseases</span>
				</NavLink>
			</div>
		</>
	)
}

export default SideBar;