import { useEffect, useState, useContext } from "react";
import AuthContext from "../../../../Context/AuthProvider";
import axios from 'axios';
import {Table, Modal, SearchBar} from '../../../../Components';
import Create from './Create';
import { base64ToImage } from "../../../../Utils/utils";
import Edit from "../../Components/Edit";
import {useData} from "../../../../Hooks";
import Pagination from "../../../../Components/Pagination";
import {toBase64} from "../../../../Utils/utils";
const rooms_api = process.env.REACT_APP_API_ROOMS;
const QRCode = (props)=>{
	const base64 = 'iVBORw0KGgoAAAANSUhEUgAAAZoAAAGaAQAAAAAefbjOAAAC/ElEQVR4nO2cXarbPBCGn/lsyKUNXUCWIu+sdEnfDqylnAUUrMsDMm8vJNnJOYVS0iZpPLow+fGDJTyZn3fkmPjtEf/7fQYccsghhxxyyKHXhKyOHrNxNZtST/1iBJtSO2F6yPQcuj8UJEkLEBbQPORiG4S3HqCTJOkaut/0HLo/lDYHkHpsYjXNqUffzrmcYGb946bn0N2g/sN7kaD4giCA1GeR/sCVHPo3IbOzBKmHaD02QTn88Ss59NzQIGkGpDczmwaJsAAM7waApPy46Tl0byiaWa0r6NSSCSC8nQSspdR41PQcuncesUvZgoziOSN4byYwXGvdT74mh26BKFVl2EMCXQsYAEEZzVsQCZI0P/maHLoFarVG+vKp5RXmtYc0YmFZe+LYNat58jU5dAtUfQRDBuhUP1tAc/MW0tJJWjq5jzgMFN76Wk2EpRPQyaYhoxmvPo8FtcwyddnC/+OeTxokA+gyccS01aFPvyaHbodsYjUYVNzDnm1Kejf7qgxxpLiMf2RNDt0MaU5mxHEtygTRTiKOnTSnkyCdvNN1AGjLLC8PUnEP+bo2rc7DM8uXhto9X1pIKMXF9lkxAQCGjFvE60P1JldXUDdJXO2U2MQprz4PAbWf/ZCr7DBTd8xchI552OOHW8SLQxcK1a5H7PulijHMdKqClVvEq0NVj4jTSVY0y7SaYO0FYAydYFiA4bvp3tNz6O7QRa1R/cEWIS56XiWZ8FrjCNBlflDbF9tXrQ4tIyh77/NAUDKzaajSZFMvAdKpVp/RXKE6ArRFCGAPE7XjWV+VEbYy1X3E60PtXmeIZ+mjowhqu3EfMz2HHukj6k4JrtzD0sk1y2NBm1xZSop5s42gbT+Nd7oOBV090wWspm+l97ka0cwIevfnPg8LbWGidTMyxUCeY3oO/UXo01N+8Zwx6DAGIFqXFcdFFpbR91AdAPqpQqV52DocFwdcoToA9FmPaA9olP6WtKeXrkccATL9+pyPw/+ZzCGHHHLIIYccAvgBzORn99crn6wAAAAASUVORK5CYII='
	return (
		<>
			<div>
				<img src={base64ToImage(base64)} alt="" srcset="" />	
					<button className="bg-gradient py-2 px-4 rounded-md text-white font-bold" type="submit">Download QR code</button>
			</div>
		</>
	)			
}
const Rooms = ()=>{
	const [modal, setModal] = useState({
		qrcode: false,
		create_form: false,
		edit_form: {
			instance: null,
			status: false
		}
	});

	const {authTokens} = useContext(AuthContext);

	const {data, query, handleQuery, addToSelected, removeFromSelected, count, page, setPage, totalPages} = useData(rooms_api, authTokens);

	const [qrImg, setQrImg] = useState({
		'entrance': null,
		'exit': null
	});

	const fetchImage = async (url, token)=>{
		// fetch image via axios
		let img;
		await axios({
			url: url,
			method: 'GET', 
			responseType: "arraybuffer",
			headers: {
				Authorization: `Token ${token}`
			}
		}).then(res=> img = toBase64(res.data))
		return img;
	}

	useEffect(()=>{
		if(modal.qrcode.status === true){

			Promise.all([
			fetchImage(`${rooms_api}/${modal.qrcode.instance}/entrance`, authTokens),
			fetchImage(`${rooms_api}/${modal.qrcode.instance}/exit`, authTokens)
			]).then(response=>
				setQrImg({...qrImg, entrance: response[0], exit: response[1]}),
			)
		}
	}, [modal.qrcode])


	const closeInstanceActions = ()=>{
		setModal({...modal, edit_form:{instance:null, status: false}});
	}
	
	const deleteRowClick = async (value)=>{
		await axios(
			{
				url: `${rooms_api}/${value.id}`, 
				method: 'DELETE', 
				headers: {
					Authorization: `Token ${authTokens}`
				}
			}
		)
			.then(res=>alert('successfully deleted'))
			.catch(err=> alert(JSON.stringify(err)));
	}

	return(
		<>
		{/* head  */}
			<div class="flex flex-row justify-between gap-4 items-center">
				<div className="inline-flex gap-4">
					<button onClick={()=>{setModal({...modal, create_form: true})}} className="bg-amber-200 outline outline-1 outline-amber-600 hover:bg-amber-400 hover:scale-[1.03] gap-2 text-black flex flex-row justify-center items-center shadow-md text-sm font-bold py-2 px-4 rounded-md">Create Room</button>										
				</div>	
				<SearchBar onChange={handleQuery} title="search" name="search" value={query.search} placeholder="Search by room name here"/>
			</div>
			{/* table */}
			<div className="mt-3">
				<Table 
					data={data}
					columns={
						[
							{column: 'building_name', name:'corresponding building'},
							{column: 'id', name: 'room code'},
							{column: 'name', name: 'room'},
						]
					}
					onRowClick={(value)=>{setModal({...modal, qrcode: {status: true, instance: value.id}})}}
					onEditClick={(value)=>{setModal({...modal, edit_form: {instance: value, status: true }})}}
					onDeleteClick={(value)=>{deleteRowClick(value)}}
				/>
			</div>
			<div className="mt-3">
				<Pagination
					count={count}
					page={page}
					setPage={setPage}
					totalPages={totalPages}
				/>
			</div>
			{/* Modals */}
			<Modal 
				title='Download QR code'
				// handleModal={setModal}
				onClose={()=>{setModal({...modal, qrcode: false})}}
				isActive={modal.qrcode.status}
			>
			<h1>entrance</h1>
				<img className="border border-slate-300 w-96 h-96" src={qrImg.entrance}/>
			<h1>exit</h1>
				<img className="border border-slate-300 w-96 h-96" src={qrImg.exit}/>
			</Modal>
			<Modal
				title="Edit Room"	
				onClose={()=>{closeInstanceActions()}}
				isActive={modal.edit_form.status}
			>
				<Edit 
					url={rooms_api} 
					authTokens={authTokens} 
					instance={modal.edit_form.instance}
					onSuccess = {()=>{closeInstanceActions()}}
				/>
			</Modal>
			<Modal 
				title='Create Room'
				// handleModal={setModal}
				onClose={()=>{setModal({...modal, create_form: false})}}
				isActive={modal.create_form}
			>
				<Create/>
			</Modal>
		</>		
	);
}
export default Rooms;