import { useState, useContext } from "react";
import {useData, useForm} from '../Hooks';
import  {Modal, Table, Table_, SearchBar, AddedFilters, PopoverMenu} from '../Components';
import AuthContext from "../Context/AuthProvider";
import axios from 'axios';
import {  Send } from "react-bootstrap-icons";
import Pagination from "../Components/Pagination";
import { convertIsoDateToDayAndHour } from "../Utils/utils";

const reports_api = process.env.REACT_APP_API_REPORTS;
const alert_reports_api = process.env.REACT_APP_API_ALERT_REPORTS;
const disease_url = process.env.REACT_APP_API_DISEASES;
const rooms_url = process.env.REACT_APP_API_ROOMS;
const buildings_url = process.env.REACT_APP_API_BUILDINGS;

const Reports = ()=>{
	const [modal, setModal] = useState(false);
	const [type, setType] = useState('all');
	const {formData, setFormData, handleInputChange } = useForm(
		{message: ''}
	);
	const {authTokens} = useContext(AuthContext);
	const {data, setData, query, handleQuery, selectedData, addToSelected, removeFromSelected, removeFromQuery, page, setPage, count, totalPages} = useData(reports_api, authTokens);
	const {data: diseases} = useData(disease_url, authTokens);
	const {data: rooms} = useData(rooms_url, authTokens);
	const {data: buildings} = useData(buildings_url, authTokens);
	// const navigate = useNavigate();

	const handleAlerts = (type)=>{
		setModal(true);
		setType(type);
	}

	const handleSelected = (row)=>{
		// console.log(row);
		addToSelected(row);			
	};

	const closeModal = ()=>{
		setModal(false);
		setFormData(false);
	}

	const postAlerts = async (object, token)=>{
		await axios({
			url: alert_reports_api + '/', 
			method: 'POST', 
			data: object,	
			headers: {
				Authorization: `Token ${token}`
			}
		})
		.then((res)=> {alert('successfully alerted!!'); closeModal()})
		.catch(err=>alert(JSON.stringify('error')));
	}

	const handleSubmit = async (e)=>{
		// console.log('base:', base_url, '/alerts');
		e.preventDefault();	
		let alerts;
		switch(type){
			case 'all':
				alerts = [...data].map((e)=>{return {
					report: e.id,
					message: formData.message
				}})
				postAlerts(alerts, authTokens);
				break;
			case 'selected':
				alerts = [...selectedData].map((e)=>{
					return {report: e.id, message: formData.message}
				});
				postAlerts(alerts, authTokens);
				break;
			case 'all_except_selected':
				alerts = [...data].filter((e)=>{return !e?.checked}).map((e)=>{
					return{
						report: e.id,
						message: formData.message
					}
				});
				postAlerts(alerts, authTokens);
				break;
		}
	}
	return(
		<div className="py-16">
			{/* header */}
			<div className="flex flex-row justify-between">
				<h1 className="font-bold text-lg">Reports</h1>
			</div>

			{/* filters row */}
			<div className="flex flex-row gap-2 justify-between items-center mb-2">

				{/* all the added filters */}
				<AddedFilters filters={query} onRemove={removeFromQuery}/>

				{/* filters menu */}
				<PopoverMenu>
					<PopoverMenu.Button className="h-12 items-center">
						Filters
					</PopoverMenu.Button>
					<PopoverMenu.Items>
						<PopoverMenu.Item>
							<label for="report">Report Code / ID</label>
							<input onChange={handleQuery} name="id" type="number" id="id" className="border border-gray-200 rounded-md px-2 w-full" placeholder="report number" value={query.report}/>
						</PopoverMenu.Item>
						<PopoverMenu.Item>
							<label for="report">Entry Code / ID</label>
							<input onChange={handleQuery} name="entry" type="number" id="entry" className="border border-gray-200 rounded-md px-2 w-full" placeholder="report number" value={query.entry}/>
						</PopoverMenu.Item>
						<PopoverMenu.Item>
							<label for="disease">Disease</label>
							<select onChange={handleQuery} name="disease" className="border border-gray-200 rounded-md px-2 w-full" placeholder="helloworld">
								<option value="">--none selected--</option>
								{diseases?.map((e)=>{
									return(
										<option value={e.id}>{e.name}</option>
									)
								})}
							</select>
						</PopoverMenu.Item>
						<PopoverMenu.Item>
							<label for="buildings">Building</label>
							<select onChange={handleQuery} name="building" className="border border-gray-200 rounded-md px-2 w-full" placeholder="buildings">
								<option value="">--null--</option>
								{buildings?.map((e)=>{
									return (
										<option value={e.id}>{e.name}</option>
									)
								})}
							</select>
						</PopoverMenu.Item>
						<PopoverMenu.Item>
							<label for="disease">Room</label>
							<select onChange={handleQuery} name="room" className="border border-gray-200 rounded-md px-2 w-full" placeholder="rooms">
								<option value="">--null--</option>
								{rooms?.map((e)=>{
									return (
										<option value={e.id}>{e.name}</option>
									)
								})}
							</select>
						</PopoverMenu.Item>
						<PopoverMenu.Item>
							<label for="date_created">Date Created</label>
							<input 
								onChange={handleQuery}
								className="border border-gray-200 rounded-md px-2"
								type="date" 
								name="date_created" 
								id="date_created" 
							/>
						</PopoverMenu.Item>
					</PopoverMenu.Items>
				</PopoverMenu>
			</div>
			<div className="flex flex-row justify-between gap-2">
				<SearchBar
					title="Selected Reports"
					name="search"
					selectedData={selectedData}	
					value={query.search}
					onChange={handleQuery}					
					onRemove={removeFromSelected}
					placeholder="Search by disease or date created, etc."
				/>
				<span>
				<PopoverMenu>
					<PopoverMenu.Button className="h-12">
						<div className="flex flex-row items-center">
							<span>
								Send
							</span>
						</div>
					</PopoverMenu.Button>
					<PopoverMenu.Items>
						<PopoverMenu.Item>
							<button onClick={()=>{handleAlerts('selected')}} className="bg-amber-200 outline outline-1 outline-amber-600 hover:bg-amber-400 hover:scale-[1.03] gap-2 text-black flex flex-row justify-center items-center shadow-md text-sm font-bold py-2 px-4 rounded-md w-full">
								<span>Send to Selected</span>
								<span><Send/></span>
							</button>					
						</PopoverMenu.Item>
					</PopoverMenu.Items>
				</PopoverMenu>
				</span>
			</div>

			{/* table */}
			<div className="mt-3">
				<Table_>
					<Table_.Head>
						{['report code / id', 'entry code / id', 'building', 'room', 'disease', 'date_created'].map((e)=>{
							return(
								<Table_.Th>{e}</Table_.Th>
							)
						})}
					</Table_.Head>
					<Table_.Body>
						{/* iterate users */}
						{data.map((row)=>{
							return(
								<Table_.Tr onClick={()=>addToSelected(row)}>
									{['id', 'entry', 'building_name', 'room_name', 'disease_name', 'date_created'].map((column)=>{
										if (column === 'date_created'){
											return(
												<Table_.Td>
													{convertIsoDateToDayAndHour(row[column])}
												</Table_.Td>
											)
										}else{
											return(
												<Table_.Td>{row[column]}</Table_.Td>
											)
										}
									})}	
								</Table_.Tr>
							)
						})}
					</Table_.Body>
				</Table_>
			</div>
			
			<Pagination
				page={page}
				count={count}
				setPage={setPage}
				totalPages={totalPages}
			/>

			<Modal
				isActive={modal} 
				onClose={closeModal}
				handleModal={setModal}
				title="Alerts"
			>
			<form onSubmit={handleSubmit} className="w-full">
				<div>
					<div className="mb-20">
						<label for="message" class="block text-sm font-medium leading-6 text-gray-900 mr-4">Message:</label>
						<div class="block mt-2 rounded-md">
							<textarea onChange={handleInputChange} class="w-full h-full border border-slate-300 rounded-md p-2" id="message" name="message" placeholder="You have been in close contact with a person..."></textarea>
						</div>
					</div>
					<div class="relative mt-2 rounded-md shadow-sm">
						<button className="bg-amber-200 outline outline-1 outline-amber-600 hover:bg-amber-400 hover:scale-[1.03] gap-2 text-black flex flex-row justify-center items-center shadow-md text-sm font-bold py-2 px-4 rounded-md" type="submit">Submit</button>
					</div>
				</div>
			</form>
			</Modal>
		</div>
	)
}

export default Reports; 