const toBase64 = (data) =>{
	const base64 = btoa(
		new Uint8Array(data).reduce(
				(data, byte) => data + String.fromCharCode(byte),
				''
		)
	);
	return `data:image/png;charset=utf-8;base64,${base64}`;
}

export default toBase64;