import { useState, useEffect, useContext } from "react";
import { Send } from "react-bootstrap-icons";
import useForm from "../../Hooks/useForm";
import axios from "axios";
import AuthContext from "../../Context/AuthProvider";
import Pagination from "../../Components/Pagination";
// import Table from "../../Components/Table";
import { Table_ } from "../../Components";
import SearchBar from "../../Components/SearchBar(1)";
import { useData } from "../../Hooks";

const users_api = process.env.REACT_APP_API_USERS;

const alerts_url = process.env.REACT_APP_API_ALERTS;
const alert_recipients_url = process.env.REACT_APP_API_ALERT_RECIPIENTS;

const Create =({onSuccess = undefined, onError = undefined})=>{	
	const {formData, handleInputChange} = useForm({
		message: ''
	});
	const {authTokens} = useContext(AuthContext);
	const {data: recipients, setData: setRecipients, query, handleQuery, selectedData, addToSelected, removeFromSelected, page, setPage, count, totalPages} = useData(users_api, authTokens);

	const handleSuccess = (res)=>{
		alert(JSON.stringify('successfully alerted')); 
		setRecipients([]);
		if(onSuccess){onSuccess()};	
	}

	const handleSubmit = async (e)=>{
		e.preventDefault();
		var alert_id = undefined;
		await axios({
			method: 'post',
			url: alerts_url + '/',
			headers: {
				'Authorization': `Token ${authTokens}`,
			},
			data: {
				message: formData.message
			}
		}).then((res)=> alert_id = res.data.id).catch(err=>alert(JSON.stringify(err)));

		await Promise.all(selectedData.map((e)=> 
			axios({
				method: 'post',
				url: alert_recipients_url + '/',
				headers: {
					'Authorization': `Token ${authTokens}`,
				},
				data: {
					alert: alert_id,
					recipient: e.id
				}
			})
		)).then((res)=>alert('request successful'))
		.catch(err=>alert('an error occured'));
	}

	return(
		<>
			<form className="w-full" onSubmit={handleSubmit}>
				<div className="flex flex-row justify-between">
					<SearchBar 
						title="Search Recipients"
						placeholder="type name, email, id"
						name="search" 
						selectedData={selectedData}
						value={query.search} 
						onChange={handleQuery}
						onRemove={removeFromSelected}
					/>
				</div>
				<div className="flex flex-row gap-2 justify-content-evenly mt-2">
				<div class="relative rounded-md shadow-sm w-full overflow-hidden">
					<div>
						<Table_>
							<Table_.Head>
								{['user id / code', 'username', 'email'].map((e)=>{
									return(
										<Table_.Th>{e}</Table_.Th>
									)
								})}
							</Table_.Head>
							<Table_.Body>
								{/* iterate users */}
								{recipients.map((row)=>{
									return(
										<Table_.Tr onClick={()=>addToSelected(row)}>
											{['id', 'username', 'email'].map((column)=>{
												return(
													<Table_.Td>{row[column]}</Table_.Td>
												)
											})}	
										</Table_.Tr>
									)
								})}
							</Table_.Body>
						</Table_>
					</div>
					<div className="mt-3">
						<Pagination
							page={page}
							setPage={setPage}
							count={count}
							totalPages={totalPages}
						/>
					</div>
					</div>
					{/* table and search  */}
				</div>
				<div>
					<label for="price" class="block text-sm font-medium leading-6 text-gray-900">Message</label>
					<div class="relative mt-2 rounded-md shadow-sm">
						<textarea type="text" name="message" onChange={handleInputChange} value={formData.message} id="message" class="block w-full rounded-md border-0 py-1.5 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Name"></textarea>
					</div>
				</div>
				<div>
					<div class="relative mt-2 rounded-md shadow-sm">
						<button type="submit" className="bg-amber-200 outline outline-1 outline-amber-600 hover:bg-amber-400 hover:scale-[1.03] gap-2 text-black flex flex-row justify-center items-center shadow-md text-sm font-bold py-2 px-4 rounded-md">
							Send Alert
							<Send/>
						</button>
					</div>
				</div>
			</form>
		</>
	)
}

export default Create;